export default class MediaGrid {
    constructor() {
        const activeParallaxElements = document.querySelectorAll<HTMLElement>('.media-grid__parallax-image');
        function scrollUpdate() {
            // parallax effect
            // doesn't work if the parallax__item has display: inline
            const parallaxRange = 20;
            const parallaxDefaultrate = 1;
            for (let i=0; i<activeParallaxElements.length; i++) {
                const target = activeParallaxElements[i];
                // the proportional amount we're adding to its normal offset from the center of the screen
                // can also be negative to scroll more slowly than normal
                let parallaxRate = parallaxDefaultrate;
                // we can't check the element's original position directly because we may have already moved it
                const targetOffsetContainer = target.closest('.media-grid__cell');

                const elOffsetMultiplier = getElementOffset(targetOffsetContainer);
                const appliedOffset = - parallaxRange * elOffsetMultiplier * parallaxRate;
                target.style.transform = `translateY(${appliedOffset}%)`;
            }
        }
        function getElementOffset(el: Element) {
            const halfWindowHeight = window.innerHeight / 2;
            const elCenter =
                el.getBoundingClientRect().y +
                el.getBoundingClientRect().height / 2;
            const elOffset = elCenter - halfWindowHeight;
            return elOffset / halfWindowHeight;
        }
        if (activeParallaxElements) {
            window.addEventListener('scroll', scrollUpdate);
            scrollUpdate(); // apply the translation immediately so things don't jump on the initial scroll
        }
    }
}

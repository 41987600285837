import { h, render, Component } from 'preact';

export default class NoTermResults extends Component {
  render(props) {
    return (
      <div>
      </div>
    );
  }
}

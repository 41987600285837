import CoursePopup from './course-popup';

export default class CoursePopupActivator {
  constructor(popupManager) {

    this.popupManager = popupManager;

    const elements = [...document.querySelectorAll('.has-course-popup')];
    if (elements.length < 1) {
      return;
    }

    const courseCache = [];

    const _this = this;
    elements.forEach(link =>
      link.addEventListener('click', e => {

        e.preventDefault();

        const courseNumber = link.dataset['courseNumber'].replace(/\s+/, ' ');

        _this.popupManager.addOrTogglePopup({
          parent: link,
          courseNumber: courseNumber,
          catalogUrl: link.getAttribute('href'),
          courseCache: courseCache,
          render: CoursePopup,
        });
      }
    ));
  }
}

import {h, render} from 'preact';

export default class HousingExplorerActivator {
  constructor() {
    const elements = [...document.getElementsByClassName('housing-explorer')];
    if (elements.length < 1) {
      return;
    }
    import(/* webpackChunkName: "housing-explorer" */ './housing-explorer').then(mod => {
      const HousingExplorer = mod.default;
      elements.forEach((element) =>
        render(
          <HousingExplorer />
          , element
        )
      );
    });
  }
}

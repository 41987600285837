import { h, render, Component } from 'preact';

export default class ResultsHeader {
  render(props) {
    const { title, resultsCount } = props;

    return (
      <div class="search__heading">
        <h3 className="hr-none">{title}</h3>
        <p>{resultsCount} result{resultsCount === 1 ? '' : 's'}</p>
      </div>
    );
  }
}

import {Component, h} from 'preact';

export default class EwuPopupManager extends Component {
  constructor() {
    super();
    this.state = {
      popups: []
    };
    this.togglePopup = this.togglePopup.bind(this);
    this.resizePopup = this.resizePopup.bind(this);
    this.closePopupWithFocusReturn = this.closePopupWithFocusReturn.bind(this);
    this.closeTimers = {};
    this.popupRefs = {};

    // close popups if the user clicks background
    window.addEventListener('click', e => {
      // if they clicked on a popup link, ignore
      if (
        e.target.closest('.has-course-popup') ||
        e.target.closest('.has-ewu-popup')
      ) {
        return;
      }
      // if they clicked on a popup itself, ignore
      if (e.target.closest('.ewu-popup')) {
        return;
      }
      // they clicked in the background - close all popups
      this.state.popups.forEach(popup => this.closePopup(popup.key));
    });
  }

  generateKey() {
    let key = '';
    for (let i = 0; i < 25; i++) {
      key += String.fromCharCode(Math.floor((Math.random() * (123-97)) + 97));
    }
    return key;
  }

  addOrTogglePopup(popup) {
    // don't add a popup if we already have it
    const existingPopup = this.state.popups.find(p => p.parent == popup.parent);

    if (existingPopup) {

      this.togglePopup(existingPopup.key);

    } else {

      popup.key = this.generateKey();
      popup.visualState = 'opening';
      const popupsCopy = this.state.popups.concat(popup);
      this.setState({
        popups: popupsCopy
      }, () => {
        // i would call openPopup here but it doesn't seem to render the opening animation correctly
        // instead it is called in the popup's componentDidMount
      });

    }
  }

  findPopup(key) {
    return this.state.popups.find(popup => popup.key == key);
  }

  togglePopup(key) {
    const popup = this.findPopup(key);
    if (popup.visualState == 'open') {
      this.closePopup(key);
    } else {
      this.openPopup(key);
    }
  }

  updatePopup(key, setting, value, callback) {
    const popupCopy = Object.assign({}, this.findPopup(key));
    popupCopy[setting] = value;
    this.setState((state) => {
      const stateUpdate = {
        popups: state.popups.map(popup => {
          return (popup.key == key) ? popupCopy : popup;
        })
      };
      return stateUpdate;
    }, () => {
      if (typeof callback == 'function') {
        callback();
      }
    });
  }

  closePopup(key) {
    const popupCopy = Object.assign({}, this.findPopup(key));
    if (['closing', 'closed'].includes(popupCopy.visualState)) {
      return;
    }
    this.updatePopup(key, 'visualState', 'closing', () => {
      this.closeTimers[key] = window.setTimeout(() => {
        this.updatePopup(key, 'visualState', 'closed');
      }, 200);
    });
  }

  openPopup(key) {
    // close other popups
    this.state.popups.forEach(popup => {
      if (popup.key != key) {
        this.closePopup(popup.key);
      }
    });

    clearTimeout(this.closeTimers[key]);
    this.resizePopup(key);
    this.updatePopup(key, 'visualState', 'opening', () => {
      const popupRef = this.findNestedPopupRef(key);
      popupRef.focus();
      this.updatePopup(key, 'visualState', 'open');
    });
  }

  closePopupWithFocusReturn(key) {
    this.closePopup(key);
    const popup = this.findPopup(key);
    popup.parent.focus();
  }

  resizePopup(key) {
    const popup = this.findPopup(key);
    const parentCoords = popup.parent.getBoundingClientRect();
    // popup will normally align left to the link which opened it
    const popupMargin = 30;
    let popupWidth = 630;
    let leftPos = parentCoords.left;
    // if screen is to narrow, fill screen but with a margin
    if (popupMargin + popupWidth + popupMargin > window.innerWidth) {
      leftPos = popupMargin;
      popupWidth = window.innerWidth - popupMargin * 2;
    }
    // otherwise don't let popup hang off the right edge
    else if (parentCoords.left + popupWidth + popupMargin > window.innerWidth) {
      leftPos = window.innerWidth - popupWidth - popupMargin;
    }
    const popupRef = this.findNestedPopupRef(key);
    popupRef.style.top = (parentCoords.bottom + window.scrollY + 10) + "px";
    popupRef.style.left = leftPos + "px";
    popupRef.style.width = popupWidth + "px";
  }

  findNestedPopupRef(key) {
    return this.popupRefs[key].popupRef.popupRef;
  }

  render() {
    return (
      <div>
        { this.state.popups.map(popup => {
          const PopupType = popup.render;
          return (
            <PopupType
              {...popup}
              ref={el => this.popupRefs[popup.key] = el}
              openPopup={() => this.openPopup(popup.key)}
              resizePopup={() => this.resizePopup(popup.key)}
              closePopupWithFocusReturn={() => this.closePopupWithFocusReturn(popup.key)}
            />
          );
        })}
      </div>
    );
  }
}

import { h, render } from 'preact';
import NoTermResults from './components/no-term-results';
import ResultsList from './components/results-list';
import NullResults from './components/null-results';
import SearchError from './components/search-error';
import PeopleResultsSection from './components/people-results';

export default class Search {
  constructor() {
    this.getReferences();
    if (this.container) {
      this.getSearchData();
      this.init();
    }
  }

  getReferences() {
    this.container = document.getElementById('search-results');
  }

  /**
   * get current search data
   *
   * all of the inital data is set in a script tag in /page-search.php
   */
  getSearchData() {
    this.term = searchTermInit || '';
    this.type = searchTypeInit || '';
    this.programs = propertySearchResults || [];
    this.people = peopleResults || [];
  }

  init() {
    if (this.type === 'EWU') {
      if (!this.term) {
        this.renderNoTerm();
        return;
      }

      this.performEwuSearch(this.term);
    }
    else if (this.type === 'people') {
      if (!this.term) {
        this.renderNoTerm();
        return;
      }

      this.performPeopleSearch();
    }
    else {
      this.renderError();
    }
  }

  performEwuSearch(query) {
    this.easternData = [];
    this.googleData = [];

    this.requestEasternData(query)
      .then(this.renderResults.bind(this))
      .catch((error) => {
        this.renderError();
        console.log({ error: error.message })
      });
  }

  performPeopleSearch() {
    render((<PeopleResultsSection title="People" results={this.people} />), this.container);
  }

  /**
   * render search results
   */
  renderResults() {
    if (!this.easternData.length || !this.googleData.length) {
      this.render
    }

    render(<ResultsList
      term={this.term}
      sections={this.sections}
      type={this.type}
      topResults={this.topResults(this.easternData)}
      departmentResults={this.programs}
    />, this.container);
  }

  /**
   * Various error endering functions
   */
  renderNoTerm() {
    render(<NoTermResults />, this.container);
  }

  renderError() {
    render(<SearchError />, this.container);
  }

  renderNull() {
    render(<NullResults />, this.container);
  }

  /**
   * request static top results data from provided json file
   */
  requestEasternData(query) {
    return fetch(`/wp-json/ewu/api/topresults?q=${query}`)
      .then(this.verifyRequest)
      .then((data) => {
        this.easternData = data;
      })
      .catch((error) => {
        return { error: error.message };
      });
  }

  /**
   * validate a response object is in a valid range
   *
   * @param {any} response
   */
  verifyRequest(response) {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }

    const error = new Error(response.statusText);
    throw error;
  }

  /**
   * data requests
   */
  topResults(data) {
    return data
      .filter(this.filterForTopResult.bind(this));
  }

  departmentResults(data) {
    return data
      .filter(this.filterForTerm.bind(this))
      .filter(this.filterForDepartment.bind(this));
  }

  /**
   * FILTERS
   */

  filterForTerm(result) {
    const regex = new RegExp(this.term, 'gi');
    const { type, longName, description, URL: url } = result;
    const match = longName.match(regex);

    return match && match.length;
  }

  filterForTopResult(result) {
    return this.checkType(result, 'topResults');
  }

  filterForDepartment(result) {
    return this.checkType(result, 'deptProg');
  }

  checkType(result, requiredType) {
    const { type } = result;

    if (!type) {
      return false;
    }

    return type === requiredType;
  }
}

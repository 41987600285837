import { h, render, Component } from 'preact';

export default class SearchError extends Component {
  render(props) {
    return (
      <div>
        <h2 class="text-center">An unexpected error occurred</h2>
      </div>
    );
  }
}

import debounce from 'lodash/debounce';
import AdmissionsExplorerActivator from '../modules/stuff-explorer/admissions-explorer-activator';
import Global from './global';
import PersonaBarActivator from '../modules/persona-bar/persona-bar-neo';
import PrimaryNav from '../modules/primary-nav/primary-nav';
import EasternStoriesActivator from '../modules/eastern-stories/eastern-stories-activator';
import StatsActivator from '../modules/stats/stats-activator';
import Hero from '../modules/hero/hero';
import MapActivator from '../modules/map/map-activator';
import Search from '../modules/search/search';
import Instagram from '../modules/instagram/instagram';
import ShareButtons from '../modules/share-buttons/share-buttons';
import SearchHeader from '../modules/search-header/search-header';
import { SearchBoxTemplatizer } from '../modules/search-box/search-box'
import CalendarEvents from '../modules/calendar-events/calendar-events';
import ImageLoader from '../modules/image-loader/image-loader';
import ProgramExplorerActivator from '../modules/program-explorer/program-explorer-activator';
import HousingExplorerActivator from '../modules/stuff-explorer/housing-explorer-activator';
import EwuPopupManagerActivator from '../modules/ewu-popup/ewu-popup-manager-actvator';
import CoursePopupActivator from '../modules/course-popup/course-popup-activator';
import GeneralModalActivator from '../modules/general-modal/general-modal-activator';
import JobListingsActivator from '../modules/job-listings/job-listings-activator';
import ExpertsExplorerActivator from '../modules/stuff-explorer/experts-explorer-activator';
import EmailSigToolActivator from '../modules/email-sig-tool/email-sig-tool-activator';
import FundExplorerActivator from '../modules/stuff-explorer/fund-explorer-activator';
import GradStudiesExplorerActivator from '../modules/grad-studies-explorer/grad-studies-explorer-activator';
import ProgramSearchBoxActivator from '../modules/program-explorer/program-search-box';
import MediaGrid from '../modules/media-grid/media-grid';
import GizmoActivator from '../modules/gizmo-activator/gizmo-activator';
import FontLoader from '../modules/font-loader/font-loader';

class EWU {
  constructor() {

    // ensure that the dynamically imported chunks come from whatever CDN this base script was fetched from
    __webpack_public_path__ = document.currentScript.src.replace(/\/[^\/]+$/, '') + '/';

    this.imageLoader = new ImageLoader();
    this.personaBarActivator = new PersonaBarActivator();
    this.nav = new PrimaryNav();
    this.easternStoriesActivator = new EasternStoriesActivator({imageLoader: this.imageLoader});
    this.statsActivator = new StatsActivator();
    this.hero = new Hero();
    this.instagram = new Instagram();
    this.shareButtons = new ShareButtons();
    this.calendarEvents = new CalendarEvents();
    this.jobListingsActivator = new JobListingsActivator();
    this.admissionsExplorerActivator = new AdmissionsExplorerActivator();
    this.searchBoxTemplatizer = new SearchBoxTemplatizer();
    this.searchHeader = new SearchHeader();
    this.search = new Search();
    this.housingExplorerActivator = new HousingExplorerActivator();
    this.ewuPopupManagerActivator = new EwuPopupManagerActivator();
    this.coursePopupActivator = new CoursePopupActivator(this.ewuPopupManagerActivator.popupManager);
    this.programExplorerActivator = new ProgramExplorerActivator(this.ewuPopupManagerActivator.popupManager);
    this.generalModalActivator = new GeneralModalActivator;
    this.expertsExplorerActivator = new ExpertsExplorerActivator();
    this.emailSigToolActivator = new EmailSigToolActivator();
    this.fundExplorerActivator = new FundExplorerActivator();
    this.gradStudiesExplorerActivator = new GradStudiesExplorerActivator(this.ewuPopupManagerActivator.popupManager);
    this.programSearchBoxActivator = new ProgramSearchBoxActivator();
    this.mapActivator = new MapActivator();
    this.mediaGrid = new MediaGrid();
    this.gizmoActivator = new GizmoActivator();
    this.fontLoader = new FontLoader();

    this.global = new Global();

    this.debounceNav = debounce((e) => {
      let el = e.target;

      if (!el.closest('#mega-mobile-nav')
          && !el.classList.contains('jsMobileNavToggle')
          && !el.parentNode?.classList.contains('jsMobileNavToggle')
      ) {
        this.nav.closeMobileNav();
      }

      if (!el.closest('.primary-nav__dropdown-container') && !el.classList.contains('primary-nav__dropdown-container')) {
        [...document.querySelectorAll('.primary-nav__has-dropdown')].forEach(
          (el) => el.classList.remove('primary-nav__has-dropdown--active')
        );
      }

      // close persona bar when clicking outside
      if (
        document.body.contains(e.target) &&
        this.personaBarActivator.componentRef &&
        !this.personaBarActivator.componentRef.mainRef.contains(e.target)
      ) {
        this.personaBarActivator.componentRef.closePanel();
      }

    }, 50);

    document.body.addEventListener('click', this.debounceNav);

  }

}

const ewu = new EWU();

import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

declare let __webpack_public_path__: string;

const cache: {[key: string]: string} = {};

export default function SwoopIcon(props: {icon: string}) {
    const [svgContent, setSvgContent] = useState("");
    if (!props.icon) {
        console.error("Icon name not specified", props);
        return;
    }
    if (!props.icon.match(/^[\w\d-]+$/)) {
        console.error("Couldn't validate icon name: " + props.icon);
        return;
    }
    const url = `${__webpack_public_path__}../assets/icons/${props.icon}.svg`;
    useEffect(() => {
        if (props.icon in cache) {
            setSvgContent(cache[props.icon]);
            return;
        }
        fetch(url)
        .then((resp) => {
          if (!resp.ok) {
            console.error(`Bad request for icon: ${resp.url}`);
            return '';
          }
          return resp.text();
        })
        .then((text) => {
            setSvgContent(text);
            cache[props.icon] = text;
        });
    }, []);
    return (
        <div
            style={{display: "inline-flex"}}
            dangerouslySetInnerHTML={{__html: svgContent}}
        ></div>
    );
}

import { h, render, Component } from 'preact';

export default class Result extends Component {
  render(props) {
    const { result } = props;
    const { type, longName, description, URL: url } = result;

    // validate data
    if (!longName || !url) {
      return null;
    }

    return (
      <div class="search-result">
        <p className="search-result__title"><a href={url}>{longName}</a></p>
        <p className="search-result__description" dangerouslySetInnerHTML={{__html: description}}></p>
        <p className="search-result__link"><a href={url}>{url}</a></p>
      </div>
    );
  }
}

import {h, render} from 'preact';

export default class GeneralModalActivator {
  constructor() {
    const elements = [...document.getElementsByClassName('has-modal')];

    if (elements.length < 1) {
      return;
    }

    import(/* webpackChunkName: "general-modal" */ './general-modal').then(mod => {
      const GeneralModal = mod.default;
      elements.forEach(function (element) {
        const selector = element.dataset['modalElementSelector'];
        const contentElement = document.querySelector(selector);
        const container = document.createElement('span');
        let modalRef = null;
        element.addEventListener('click', () => {
          modalRef.openModal();
        });
        render(
          <GeneralModal ref={el => modalRef = el}>
            <div dangerouslySetInnerHTML={{__html: contentElement.innerHTML}}></div>
          </GeneralModal>
          , container
        );
      });
    });
  }
}

export default class Hero {
  constructor() {

    document.querySelectorAll('.hero').forEach((hero) => {

      const video = hero.querySelector('video');
      const control = hero.querySelector('.hero__video-control');

      if (!video || !control) {
        return;
      }

      const reduceMotionQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

      if ( !reduceMotionQuery?.matches && (window.innerWidth > 992)) {   // This statement ensures the video autoplays when in desktop view (Res. greater than 992px)
        video.play();
      } else { // Res. less than 992px (mobile/tablet)
        control.classList.toggle('play'); // Toggles the play/pause button to indicate the video is currenly paused
      }

      control.addEventListener('click', () => {
        const isPlaying = control.classList.toggle('play');
        if (isPlaying) {
          video.pause();
        } else {
          video.play();
        }
      });
    });
  }
}

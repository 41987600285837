import { h, render, Component } from 'preact';
import { Program } from '../program-explorer/program-explorer-types';

declare let nonce_markup: string;
declare let captchaSiteKey: string;
declare let grecaptcha: any;

declare let wp: any;
declare let dataLayer: any[];

class SearchBoxTemplatizer {
  constructor() {
    [...document.getElementsByClassName('search-box-templatizer')].forEach(function (element: HTMLElement) {
      render(<SearchBox
        alignment={element.dataset['alignment']}
        style={element.dataset['style']}
        showOptions={element.dataset['showOptions'] == "1" ? true : false}
        searchTermInit=''
        searchTypeInit='EWU'
      />, element);
    });
  }
}

type sbProps = {
  searchTermInit: string,
  alignment: string,
  style: string,
  showOptions: boolean,
  searchTypeInit: 'people' | 'EWU',
};

type findable = {
  title: string,
  snippet: string,
  link: string,
  searchCorpus: string,
  type: 'program' | 'topResult',
};

type sbState = {
  q: string,
  queryWords: string[],
  searchType: 'people' | 'EWU',
  captchaKey: string,
  loading: boolean,
  findables: findable[],
  findablesToDisplay: findable[],
  topResultsStatus: 'initial' | 'loading' | 'loaded',
  programsStatus: 'initial' | 'loading' | 'loaded',
  focusItem: number, // -1 indicates search input; otherwise its the index of the result currently selected
  closeResults: boolean,
};

type topResultsResponseItem = {
  longName: string,
  description: string,
  URL: string,
  keywords: string,
};

class SearchBox extends Component<sbProps, sbState> {

  state: sbState;
  reCaptchaContainer?: HTMLElement;
  inputField?: HTMLElement;
  setReCaptchaContainerRef: (element: HTMLElement) => void;
  setInputFieldRef: (element: HTMLElement) => void;
  containerRef?: HTMLElement;

  constructor(props: sbProps) {
    super();
    this.setState({
      q: props.searchTermInit || '',
      queryWords: props.searchTermInit.toLowerCase().split(/[^a-z0-9]+/).filter((term) => term.length > 1),
      searchType: props.searchTypeInit || 'EWU',
      captchaKey: captchaSiteKey,
      loading: false,
      findables: [],
      findablesToDisplay: [],
      topResultsStatus: 'initial',
      programsStatus: 'initial',
      focusItem: -1,
      closeResults: false,
    });
    this.reCaptchaContainer = null;
    this.setReCaptchaContainerRef = element => {
        this.reCaptchaContainer = element;
    };
    this.inputField = null;
    this.setInputFieldRef = element => {
      this.inputField = element;
    }
    this.containerRef = null;
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
  }

  focusInputField() {
    this.inputField.focus();
  }

  onInputUpdate(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    const q = target.value;
    const queryWords = q.toLowerCase().split(/[^a-z0-9]+/).filter((term) => term.length > 1)
    this.setState({
      q: target.value,
      queryWords: queryWords,
      closeResults: false,
    }, () => {
      this.updateResults();
    });

    this.loadTopResults();
    this.loadPrograms();
  }

  updateResults() {
    const filteredFindables = this.state.findables.filter(item => (
      this.state.queryWords.every(word => item.searchCorpus.includes(word))
    ));

    const sortedFindables = filteredFindables.sort((a, b) => this.compareItems(a, b));
    this.setState({
      findablesToDisplay: sortedFindables.slice(0, 10),
    });
  }

  compareItems(a: findable, b: findable) {
    const aMatchesAll = this.state.queryWords.every(word => a.title.toLowerCase().includes(word));
    const bMatchesAll = this.state.queryWords.every(word => b.title.toLowerCase().includes(word));
    let result = 0;
    if (aMatchesAll && a.title.toLowerCase().startsWith(this.state.queryWords[0])) {
      return -1;
    } else if (bMatchesAll && b.title.toLowerCase().startsWith(this.state.queryWords[0])) {
      return 1;
    } else if (aMatchesAll) {
      result = -1;
    } else if (bMatchesAll) {
      result = 1;
    } else if (a.title < b.title) {
      result = -1;
    } else {
      result = 1;
    }
    return result;
  }

  stringMatchesAll(targetText: string, searchTokens: string[]) {
    const targetTextTokens = targetText.toLowerCase().split(/[^a-z0-9]+/).filter((token) => token.length > 1);
    const result = searchTokens.every(token => targetTextTokens.includes(token));
    return result;
  }

  onInputFocus() {
    this.loadPrograms();
    this.loadTopResults();
    this.setState({
      closeResults: false,
    });
  }

  loadPrograms() {
    if (this.state.programsStatus === 'initial') {
      this.setState({
        programsStatus: 'loading',
      }, () => {
        const url = '/wp-json/ewu/api/program-explorer-json'; // always use the main site
        fetch(url).then(
          response => response.json()
        ).then((response: {programs: Program[]}) => {
          this.setState(prevState => {
            const programFindables: findable[] = response.programs.map(program => ({
              title: program.title,
              searchCorpus: `${program.title} ${program.content}`.toLowerCase(),
              link: program.reference_link.url,
              snippet: '',
              type: 'program',
            }));
            return {
              findables: prevState.findables.concat(programFindables),
              programsStatus: 'loaded',
            };
          }, () => {
            this.updateResults();
          });
        });
      });
    }
  }

  loadTopResults() {
    if (this.state.topResultsStatus === 'initial') {
      this.setState({
        topResultsStatus: 'loading',
      }, () => {
        const url = '/wp-json/ewu/api/topresults'; // always use the main site
        fetch(url).then(
          response => response.json()
        ).then((response: topResultsResponseItem[]) => {
          this.setState(prevState => {
            const trFindables: findable[] = response.map(item => ({
              title: item.longName,
              link: item.URL,
              snippet: item.description,
              searchCorpus: `${item.longName} ${item.description} ${item.keywords}`.toLowerCase(),
              type: 'topResult',
            }));
            return {
              findables: prevState.findables.concat(trFindables),
              topResultsStatus: 'loaded',
            };
          }, () => {
            this.updateResults();
          });
        });
      });
    }
  }

  /**
   * Handle selecting of types
   *
   * @param {event} event
   */
  onTypeSelect(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    this.setState({ searchType: target.value === 'people' ? 'people' : 'EWU'});
  }

  onSubmit(event: Event) {
    const { captchaKey, searchType } = this.state;
    const target = event.currentTarget as HTMLFormElement;

    this.setState({
      loading: true
    });

    if (searchType === 'EWU' && this.state.focusItem !== -1 && !this.state.closeResults) {
      const selectedItem: findable = this.state.findablesToDisplay[this.state.focusItem];
      window.location.href = selectedItem.link;
      event.preventDefault();
      return;
    }

    if (searchType === 'people') {
      event.preventDefault();
      var scriptTag = document.createElement('script');
      scriptTag.src = 'https://www.google.com/recaptcha/api.js';
      scriptTag.addEventListener('load', () => {
        grecaptcha.ready(() => {
          const captcha = grecaptcha.render(
            this.reCaptchaContainer,
            {
              sitekey: captchaKey,
              size: "invisible",
              callback: () => target.submit()
            }
          );
          grecaptcha.execute(captcha);
        });
      });
      document.head.appendChild(scriptTag);
    }
  }

  onKeyDown(e: KeyboardEvent) {
    let newFocusItem = -1;

    if (e.key === "Escape") {
      this.setState({
        q: '',
        queryWords: [],
        findablesToDisplay: []
      });
      return;
    } else if (e.key === "ArrowUp") {
      newFocusItem = this.state.focusItem - 1;
    } else if (e.key === "ArrowDown") {
      newFocusItem = this.state.focusItem + 1;
    } else {
      return;
    }

    e.preventDefault();

    // bounds check
    if (newFocusItem >= this.state.findablesToDisplay.length) {
      newFocusItem = -1;
    } else if (newFocusItem < -1) {
      newFocusItem = this.state.findablesToDisplay.length - 1;
    }

    this.setState({
      focusItem: newFocusItem
    });
  }

  closeResults() {
    this.setState({
      closeResults: true,
    });
  }

  onResultClick(linkTitle: string) {
    dataLayer.push({
      event: 'site_event',
      event_category: 'Search Box',
      event_action: 'Quick Result Click',
      event_label: linkTitle,
    });
  }

  handleClickOutside(e: MouseEvent) {
    if (
      this.state.topResultsStatus !== 'initial' &&
      this.state.programsStatus !== 'initial' &&
      !this.state.closeResults &&
      this.containerRef &&
      !this.containerRef.contains(e.target as HTMLElement)
    ) {
      this.closeResults();
    }
  }

  highlight(text: string) {
    let highlighted = text;
    for (let i = 0; i < this.state.queryWords.length; i++) {
      const re = new RegExp(`(${this.state.queryWords[i]})`, 'ig');
      highlighted = highlighted.replace(re, '<strong>$1</strong>');
    }
    return highlighted;
  }

  /**
   * render the component
   *
   * @param {any} props
   * @param {any}} state
   */
  render() {
    const { searchType, q } = this.state;
    let containerClassName = "search-box";
    if (this.props.style == 'mini') {
      containerClassName += " search-box--mini";
    }
    let formClassName = "search-box__form";
    if (this.props.alignment == 'left') {
      formClassName += " search-box__form--align-left";
    }
    let buttonClass = "search-box__button";
    if (this.state.loading) {
      buttonClass += " search-box__button--loading";
    }
    return (
      <div className={containerClassName}>
        <form
            className={formClassName}
            onSubmit={(e) => this.onSubmit(e)}
            role="search"
            method={searchType !== 'people' ? 'get' : 'post'}
            action="/search"
        >
          <div ref={this.setReCaptchaContainerRef}></div>
          <div
            className="search-box__search"
            ref={(el) => this.containerRef = el}
          >
            <div
              class="search-box__input-wrap"
              style={{position: "relative"}}
              onKeyDown={(e) => this.onKeyDown(e)}
            >
              <div>
                {searchType == 'people' && (
                  <div dangerouslySetInnerHTML={{ __html: nonce_markup }} />
                )}
              </div>
              <input
                className="search-box__input data-hj-whitelist"
                type="text"
                name="q"
                placeholder="Search"
                value={q}
                onChange={(event: Event) => this.onInputUpdate(event)}
                onFocus={() => this.onInputFocus()}
                ref={this.setInputFieldRef}
                readonly={this.state.loading}
                aria-label="Search Text"
                autocomplete="off"
              />
            </div>
            <button
              className={buttonClass}
              type="submit"
              aria-label="Submit Search"
              disabled={this.state.loading}
            >
              {
                this.state.loading
                  ? (<span class="fa fa-fw fa-gear fa-spin"></span>)
                  : (<span class="fa fa-fw fa-search"></span>)
              }
            </button>
            {this.state.q.length > 1 && !this.state.closeResults && this.state.searchType === 'EWU' && (
              <div className="search-box__quick-results">
                {this.state.findablesToDisplay.length > 0 && (
                  <ul className="search-box__quick-results-list">
                    {this.state.findablesToDisplay.map((result, index) => (
                      <li key={result.title}>
                        <a
                          className={"search-box__quick-results-result" + (index === this.state.focusItem ? ' search-box__quick-results-result--selected' : '')}
                          href={result.link}
                          onClick={() => this.onResultClick(result.title)}
                        >
                          <div dangerouslySetInnerHTML={{__html: this.highlight(result.title)}}></div>
                          {result.type === 'program' && (
                            <div class="search-box__quick-results-badge">EWU Program</div>
                          )}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a className={"search-box__quick-results-result"}
                        href={`/search?q=${q}`}
                      ><div><em>All Results</em> &gt;</div></a>
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>

          { this.props.showOptions ? (
            <div className="search-box__options" role="radiogroup" aria-label="Search Options">
              <label className="search-box__option">
                <div className="checkbox">
                  <input
                    className="search-box__radio"
                    type="radio"
                    name="search-type"
                    value="EWU"
                    checked={searchType === 'EWU'}
                    onChange={(event) => this.onTypeSelect(event)}
                  />
                  <span className="marker"></span>
                  EWU
                </div>
              </label>
              <label className="search-box__option">
                <div className="checkbox">
                  <input
                    className="search-box__radio"
                    type="radio"
                    name="search-type"
                    value="people"
                    checked={searchType === 'people'}
                    onChange={(event) => this.onTypeSelect(event)}
                  />
                  <span className="marker"></span>
                  People
                </div>
              </label>
            </div>
          ) : (
            ''
          )}

        </form>
      </div>
    );
  }
}

export { SearchBoxTemplatizer, SearchBox };

import {h, render} from 'preact';
import * as se from './stuff-explorer-types';
import * as fe from './fund-explorer-types';

declare let fundExplorerItems: se.Item[];
declare let fundExplorerConfig: fe.FeConfig;
declare let fundExplorerCaptchaKey: string;

export default class FundExplorerActivator {
  constructor() {
    const elements = [...document.getElementsByClassName('fund-explorer-app')];
    if (elements.length < 1) {
      return;
    }
    import(/* webpackChunkName: "fund-explorer" */ './fund-explorer').then(mod => {
      const FundExplorer = mod.default;
      elements.forEach((element: HTMLElement) =>
        render(
          <FundExplorer
            items={fundExplorerItems}
            config={fundExplorerConfig}
            captchaKey={fundExplorerCaptchaKey}
          />
          , element, element.querySelector('.fund-explorer-loading-message')
        )
      );
    });
  }
}


import { h, render } from 'preact';
import { SearchBox } from '../search-box/search-box';

class SearchHeader {
  constructor() {
    this.getReferences();

    if (this.container) {
      this.renderSearch();
    }
  }

  getReferences() {
    this.container = document.getElementById('searchHeader');
  }

  renderSearch() {
    const term = (typeof searchTermInit !== 'undefined') ? searchTermInit : '';
    const type = (typeof searchTypeInit !== 'undefined') ? searchTypeInit : '';
    render(<SearchBox
      alignment="left"
      searchTermInit={term}
      searchTypeInit={type}
      showOptions="1"
    />, this.container);
  }
}

export default SearchHeader;

import { h, render, Component } from "preact";

export default class GoogleResults extends Component {
  componentDidMount() {
    (function () {
      var cx = '000334732048710498985:ocg4zlgidyc';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
    })();
  }

  render(props) {
    return (
      <section class="search__result-section">
        <section className="search__result-section">
          <div class="search__heading">
            <h3 className="hr-none">Search Results</h3>
          </div>

          <div dangerouslySetInnerHTML={{ __html: '<gcse:searchresults-only></gcse:searchresults-only>' }} />
        </section>
      </section>
    );
  }
}

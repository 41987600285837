import {h, render} from 'preact';
import EwuPopupManager from '../ewu-popup/ewu-popup-manager';
import * as pe from '../program-explorer/program-explorer-types';

declare let programExplorerAppData: pe.ProgramExplorerData;

export default class GradStudiesExplorerActivator {
  constructor(popupManager: EwuPopupManager) {
    const elements = [...document.getElementsByClassName('grad-studies-explorer')];
    if (elements.length < 1) {
      return;
    }
    import(/* webpackChunkName: "grad-studies-explorer" */ './grad-studies-explorer').then(mod => {
      const GradStudiesExplorer = mod.default;
      elements.forEach((element: HTMLElement) =>
        render(
          <GradStudiesExplorer
            programs={programExplorerAppData.programs}
            degrees={programExplorerAppData.degrees}
            filters={programExplorerAppData.filters}
            bgImageUrl={element.dataset['bgImageUrl']}
            popupManager={popupManager}
            gradStudiesApplyText={element.dataset['gradStudiesApplyText']}
            gradStudiesApplyUrl={element.dataset['gradStudiesApplyUrl']}
            programApplyText={element.dataset['programApplyText']}
            programApplyUrl={element.dataset['programApplyUrl']}
          />
          , element
        )
      );
    });
  }
}

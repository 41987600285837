import {h, render} from 'preact';

export default class JobListingsActivator {
  constructor() {
    const elements = [...document.getElementsByClassName('job-listings-gizmo')];
    if (elements.length < 1) {
      return;
    }
    import(/* webpackChunkName: "job-listings" */ './job-listings').then(mod => {
      const JobList = mod.default;
      elements.forEach((element: HTMLElement) =>
        render(
          <JobList
            fetchUrl={element.dataset['feedUrl']}
            maxListings={element.dataset['maxListings']}
          />
          , element
        )
      );
    });
  }
}

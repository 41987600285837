class ShareButtons {
  constructor() {
    document.querySelectorAll('[data-module="share-buttons"] button[data-link]').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        debugger;
        const link = event.currentTarget.dataset.link;
        const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

        const popupWidth = 600;
        const popupHeight = 300;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const left = ((width / 2) - (popupWidth / 2)) + dualScreenLeft;
        const top = ((height / 2) - (popupHeight / 2)) + dualScreenTop;

        window.open(link, 'MyWindow', `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`);
      });
    });
  }
}

export default ShareButtons;

export default class StatsActivator {
  constructor() {
    const element = document.querySelector('.stats');
    if (!element) {
      return;
    }
    import(/* webpackChunkName: "stats" */ './stats').then(mod => {
      const Stats = mod.default;
      new Stats();
    });
  }
}

import { h, render, Component } from 'preact';

export default class ResultPerson extends Component {
  render(props) {
    const { result } = props;
    const {
      businessPhones, // string[]
      department, // string
      givenName, // string - first name
      jobTitle, // string
      mail, // string - email
      officeLocation, // string
      surname, // string - last name
      userPrincipalName, // string
    } = result;

    const phone = businessPhones || [];

    // validate data
    if (!givenName || !surname || !jobTitle) {
      return null;
    }

    return (
      <div class="search-result person">
        <div className="person-result">
          <h3 className="person-result__title search-result__title hr-none">{givenName} {surname}</h3>
          <div class="person-result__content">
            <p>
              {jobTitle && (
                <div><strong><em>{jobTitle}</em></strong></div>
              )}
              {department && (
                <div>{department}</div>
              )}
              {officeLocation && (
                <div>{officeLocation}</div>
              )}
              {mail && (
                <div><a href={`mailto:${mail}`}>{mail}</a></div>
              )}
              {phone.map((item) => (
                <span>{item} <br/></span>
              ))}
            </p>

          </div>
        </div>
      </div>
    );
  }
}

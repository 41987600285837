import {Component, h, createRef} from 'preact';
import Popup from '../ewu-popup/ewu-popup';

export default class CoursePopup extends Component {

  constructor(){
    super();
    this.state = {
      loaded: false,
      title: '',
      desc: '',
    }
    this.popupRef = createRef();
  }

  componentDidMount() {
    const courseData = this.props.courseCache.find(item => item.courseNumber === this.props.courseNumber);

    if (courseData) {
      this.setCourseData(courseData);
    } else {
      // get the info for all courses matching this subject code since there are probably more on this page
      const subjectCode = this.props.courseNumber.replace(/^(\w+).*$/, '$1');;
      const rawUrl = `https://catalog.ewu.edu/ribbit/?page=getcourse.rjs&subject=${encodeURIComponent(subjectCode)}`;
      const fetchUrl = `/wp-content/themes/ewu-2018/proxy.php?url=${encodeURIComponent(rawUrl)}`;
      fetch(fetchUrl)
      .then(response => {
        return response.text();
      })
      .then(xml => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(xml, 'text/xml');
        const courseElements = doc.querySelectorAll('course');
        [...courseElements].forEach(el => {
          const courseDoc = parser.parseFromString(el.textContent, 'text/xml');
          this.props.courseCache.push({
            title: courseDoc.querySelector('.courseblocktitle').textContent,
            desc: courseDoc.querySelector('.courseblockdesc').innerHTML,
            courseNumber: el.getAttribute('code'),
          });
        });
        const courseData = this.props.courseCache.find(item => item.courseNumber === this.props.courseNumber);
        this.setCourseData(courseData);
      });
    }
  }

  setCourseData(courseData) {
    this.setState({
      title: courseData.title,
      desc: courseData.desc,
      loaded: true,
    });
  }

  render () {
    return (
      <Popup
        {...this.props}
        ref={el => this.popupRef = el}
        dialogLabel="Course Info Popup"
        title={
          this.state.loaded
          ? this.state.title
          : this.props.courseNumber
      }>
        {
          this.state.loaded
          ? <div className="mb-3" dangerouslySetInnerHTML={{__html: this.state.desc}}></div>
          : <p>Loading...</p>
        }
        <p className="mb-0">
          <a target="_blank" href={this.props.catalogUrl}>
            Catalog Listing
            <span className="ml-1 fa fa-external-link"></span>
          </a>
        </p>
      </Popup>
    );
  }
}

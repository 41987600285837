import { h, render, Component } from 'preact';

class CalendarEvents {
  constructor() {
    [...document.getElementsByClassName('events-fetcher-gizmo')].forEach(function (element) {
      render(
        <CalendarEventsList
          link={element.dataset['link']}
          feedUrl={element.dataset['feedUrl']}
          limit={element.dataset['limit']}
          columnClass={element.dataset['columnClass']}
        />
        , element
      );
    });
  }
}

class CalendarEventsList extends Component {
  constructor() {
    super();
    this.state = {
      calendarEvents: [],
      requestComplete: false,
    };
  }

  componentDidMount() {
    const that = this;
    fetch(this.props.feedUrl)
    .then(function(response) {
      return response.json();
    })
    .then(function(items) {
      if (that.props.limit) {
        items = items.slice(0, that.props.limit);
      }
      that.setState({
        requestComplete: true,
        calendarEvents: items.map(function (item) {
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const date = new Date(item.startDateTime);
          let customFields = {};

          if ('customFields' in item) {
            item.customFields.forEach(function (field) {
              customFields[field.label] = field.value;
            });
          }

          return {
            title: item.title,
            customFields: customFields,
            monthName: monthNames[date.getMonth()],
            dayNumber: date.getDate(),
            eventID: item.eventID,
          };
        }),
      });
    });
  }

  render() {
    const CalendarEvent = (props, event) => (
      <article className={ "calendar-event " + props.columnClass} aria-label={event.title}>
        <time datetime={event.dateTimeFormatted}>
          <span class="day">{event.dayNumber}</span>
          <span class="month">{event.monthName}</span>
        </time>
        <div class="calendar-event--info">
          <div class="h5 hr-small hr-snug mb-2"><a target="_blank" href={props.link + '?trumbaEmbed=view%3Devent%26eventid%3D' + event.eventID}>{event.title}</a></div>
          <p>{event.customFields['Categories']}</p>
        </div>
      </article>
    );
    const calendarEvents = this.state.calendarEvents.map(e => CalendarEvent(this.props, e));
    return (
      <div className="row pt-2">
        {
          this.state.calendarEvents.length
          ? calendarEvents
          : (
            <div class="col">
              <p class="my-3 text-center">
                {
                  this.state.requestComplete
                  ? 'No events scheduled.'
                  : 'Getting events...'
                }
              </p>
            </div>
          )
        }
      </div>
    );
  }
}

export default CalendarEvents;

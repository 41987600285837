/* global wp */
import { h, render, Component, Fragment } from 'preact';
import { SearchBox } from '../search-box/search-box'
import SwoopIcon from '../swoop-icon/swoop-icon';

const NAV_CATEGORIES = window.nav_items;
const NAV_PRIMARY = wp.primary_nav;

function joinArray (array, item) {
  const newArray = [];
  for (let i=0, l=array.length; i<l; i++) {
    newArray.push(array[i]);
    if (i<l-1) {
      newArray.push(item);
    }
  }
  return newArray;
}

class MobileNavSubNav extends Component {
  render(props) {
    return (
      <div
        className="ewu-accordion-target"
        data-accordion-id={props.navId}
      >
        <div class='mobile-nav-collapse-group__listing'>
          { (props.linkUrl && props.linkText) &&
            <a href={props.linkUrl} className="mobile-nav-collapse-group__link">{props.linkText}</a>
          }
          {props.subNav && props.subNav.map(nav => {
            const navId = `collapse-${props.navId}-${nav.label.replace(/[^\w]/g, '')}`;
            return (
              nav.children
              ?
                <div className='mobile-subnav'>
                  <a
                    className='sub-nav-title ewu-accordion-toggler'
                    data-accordion-target={navId}
                  >
                    <span>
                      {nav.label}
                    </span>
                    <span className='fa fa-chevron-down ewu-accordion-toggler__open-indicator' />
                  </a>
                  <div className='ewu-accordion-target' data-accordion-id={navId}>
                    <div className='mobile-subnav__links'>
                      {nav.children.map(n => {
                        return <a href={n.link} target={n.new_tab ? '_blank' : ''}>{n.label}</a>;
                      })}
                    </div>
                  </div>
                </div>
              :
                <div className='mobile-subnav'>
                  <a
                    className='sub-nav-title'
                    href={nav.link}
                    target={nav.new_tab ? '_blank' : ''}
                  >
                    <span>
                      {nav.label}
                    </span>
                  </a>
                </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class MobileNav extends Component {

  /**
   * Fixes the situation where user clicks a # link and the page scrolls
   * but the menu stays open.
  */
  handlePlainLinkClick() {
    this.props.innerToggler.click();
  }

  render(props) {
    const { nav, sidebarLinks, footer } = props;
    const navCategories = Object.keys(nav);

    return (
      <div className='mobile-nav-container'>
        <div className='mobile-nav-search'>
          <SearchBox
            style="mini"
            alignment="left"
            searchTermInit=""
            searchTypeInit=""
            showOptions="1"
          />
        </div>
        <div className='mobile-nav-dropdowns'>
          {navCategories.map((categoryTitle, index) => {
            const navCategory = nav[categoryTitle];
	          const newTab = navCategory.new_tab;

            if (navCategory.links && navCategory.links.length > 0) {

              const navId = 'collapse-' + categoryTitle.trim().replace(/[^\w]+/g, '-').toLowerCase();
              const linkText = navCategory.feature && navCategory.feature.hasOwnProperty('mobile_link_text') ? navCategory.feature.mobile_link_text : '';
              const linkUrl  = navCategory.feature
                && navCategory.feature.hasOwnProperty('cta')
                && navCategory.feature.cta
                && navCategory.feature.cta.hasOwnProperty('url')
                ? navCategory.feature.cta.url : '';
              return (
                <div className='mobile-nav-collapse-group'>
                  <a
                    className='nav-title ewu-accordion-toggler'
                    data-accordion-target={navId}
                  >
                    <span>
                      {categoryTitle}
                    </span>
                    <span class='ewu-accordion-toggler__open-indicator'>
                      <span className='fa fa-chevron-down' />
                    </span>
                  </a>
                  <MobileNavSubNav
                    linkText={linkText}
                    linkUrl={linkUrl}
                    navId={navId}
                    subNav={navCategory.links} />
                </div>
              );
            } else {
              return (
                <a
                  className={"nav-title" + (navCategory.current ? ' nav-title--current' : '')}
                  href={navCategory.url}
                  target={newTab ? '_blank' : ''}
                  onClick={() => this.handlePlainLinkClick()}
                >
                  <span>
                    {categoryTitle}
                  </span>
                </a>
              );
            }
          })}
        </div>
        <div className='mobile-nav-footer'>
          <div className='widget-buttons-wrap'>

            {sidebarLinks.map((link) => (
              <a
                className='widget-button'
                href={link.url}
                onClick={() => this.handlePlainLinkClick()}
                target={link.new_tab ? '_blank' : ''}
              >
                <span className={`widget-button__icon ea`}>
                  <SwoopIcon icon={link.icon}/>
                </span>
                <span className='widget-button__label'>{link.title}</span>
              </a>
            ))}

          </div>

          {!footer.disable && (
            <Fragment>
              <div className='mobile-nav-contact'>
                {Array.isArray(footer.secondary_nav_items) && (
                  joinArray(footer.secondary_nav_items.map((item) => (
                    <a
                      href={item.url}
                      target={item.new_tab ? '_blank' : ''}
                    >{item.title}</a>
                  )), <span> | </span>)
                )}
              </div>

              <div className='mobile-nav-social'>
                {Array.isArray(footer.icon_nav_items) && (
                  footer.icon_nav_items.map((item, index) => (
                    <a
                      href={item.url}
                      aria-label={item.title}
                      target={item.new_tab ? '_blank' : ''}
                    >
                      <span
                        className={`${item.css_classes}`}
                        aria-hidden='true'
                      />
                    </a>
                  ))
                )}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default class PrimaryNav {
  constructor() {
    this.mobileNavToggler = document.querySelectorAll('.jsMobileNavToggle');
    this.mobileNavInnerToggler = document.querySelector('.mega-mobile-nav__close-button');
    this.mobileNav = document.querySelector('#mega-mobile-nav');

    const mobileNavEl = document.getElementById('mobileNav');
    if (mobileNavEl) {
      render(<MobileNav
          navCategories={NAV_CATEGORIES}
          nav={NAV_PRIMARY}
          innerToggler={this.mobileNavInnerToggler}
          sidebarLinks={mobileNavSidebarLinks}
          footer={JSON.parse(mobileNavEl.dataset['footer'])}
        />,
        mobileNavEl
      );
    }

    [...this.mobileNavToggler].forEach((el) => el.addEventListener('click', (e) => {
      e.preventDefault();

      if (this.mobileNav.classList.contains('open')) {
        this.closeMobileNav();
      } else {
        this.mobileNav.classList.add('open');
        document.body.style.overflowY = 'hidden';
      }
    }));

    // Plain dropdowns and mega menus
    [...document.querySelectorAll('.primary-nav__has-dropdown')].forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        // nudge the dropdown over if it's hanging off the screen, or close
        // (plain dropdowns only)
        const dropdownNode = e.currentTarget.parentNode.querySelector('.primary-nav__plain-dropdown');
        if (dropdownNode) {
          const dropdownCoords = dropdownNode.getBoundingClientRect();
          const rightMargin = 30;
          let overhang = (dropdownCoords.right + rightMargin) - window.innerWidth;
          if (overhang > 0) {
            dropdownNode.style.left = (overhang * -1) + "px";
          }
        }
        // Hide other menus
        [...document.querySelectorAll('.primary-nav__has-dropdown')].forEach((el) => {
          if (e.currentTarget !== el) {
            el.classList.remove('primary-nav__has-dropdown--active');
          }
        });
        // Show the clicked menu
        e.currentTarget.classList.toggle('primary-nav__has-dropdown--active');
        // Load megamenu image
        const img = e.currentTarget.parentNode.querySelector('img');
        if (img && 'manuallyLoadedSrc' in img.dataset && img.dataset.manuallyLoadedSrc && !img.getAttribute('src')) {
          img.setAttribute('src', img.dataset.manuallyLoadedSrc);
        }
        // if they're opening the menu, focus the search input box if present
        if (e.currentTarget.classList.contains('primary-nav__has-dropdown--active')) {
          const input = e.currentTarget.parentNode.querySelector('.search-box__input');
          if (input) {
            // this is some kind of race condition but not sure how else to make it happen without doing complicated things
            setTimeout(() => input.focus(), 200);
          }
        }
      })
    });

    [...document.querySelectorAll('.primary-nav__has-dropdown, .primary-nav__dropdown')].forEach((el) => {
      el.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          // Close menus
          [...document.querySelectorAll('.primary-nav__has-dropdown')].forEach((el) => {
            el.classList.remove('primary-nav__has-dropdown--active');
          });
          // Focus parent link that would open this menu
          const parentLink = e.currentTarget.closest('li.nav-item').querySelector('.primary-nav__has-dropdown');
          parentLink.focus();
        }
      });
    });

    [...document.querySelectorAll('.ewu-accordion-toggler')].forEach((el) => {
      el.addEventListener('click', (e) => {
        const targetStr = el.dataset.accordionTarget;
        const targetNodeSelector = `.ewu-accordion-target[data-accordion-id=${targetStr}]`;
        const targetNode = document.querySelector(targetNodeSelector);
        if (!targetNode) {
          console.log(`Target node not found: ${targetNodeSelector}`)
          return;
        }
        el.classList.toggle('ewu-accordion-toggler--active');
        el.ariaExpanded = el.ariaExpanded == "true" ? "false" : "true";
        targetNode.classList.toggle('ewu-accordion-target--active');
      });
    });
  }


  closeMobileNav() {
    if (this.mobileNav) {
      this.mobileNav.classList.remove('open');
    }
    document.body.style.overflowY = '';
  }
}

import {Component, h, render} from 'preact';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import SwoopIcon from '../swoop-icon/swoop-icon';

declare let __webpack_public_path__: string;

type PbProps = {
  nav: NavItem[],
};

type PbState = {
  selectedTabSlug: string,
  panelOpen: boolean,
};

type NavItem = {
  slug: string,
  title: string,
  url: string,
  new_tab: boolean,
  icon: string,
  children: NavItem[],
};

class PersonaBar extends Component<PbProps, PbState> {
  mainRef?: HTMLElement;
  mainButtonRef?: HTMLElement;
  panelRefs: {[key: string]: HTMLElement};
  buttonRefs: {[key: string]: HTMLElement};

  constructor(){
    super();
    this.mainRef = null;
    this.mainButtonRef = null;
    this.panelRefs = {};
    this.buttonRefs = {};
    this.state = {
      selectedTabSlug: 'current-students',
      panelOpen: false,
    };
  }

  componentDidMount () {
    try {
      const localStorageVars = JSON.parse(localStorage.getItem('ewu_persona'));
      this.setState({
        // backwards compatibility with old var name
        selectedTabSlug: localStorageVars.id.replace(/-tab$/, ''),
      });
    } catch (e) {
      return;
    }
  }

  updateLocalStorage () {
    localStorage.setItem('ewu_persona', JSON.stringify({
      id: this.state.selectedTabSlug
    }));
  }

  handleExpanderClick () {
    this.setState({
      panelOpen: !this.state.panelOpen,
    });
  }

  closePanel () {
    this.setState({
      panelOpen: false,
    });
  }

  handleMainKeyDown (e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.mainButtonRef.focus();
      this.closePanel();
    }
  }

  handleLinksKeyDown (e: KeyboardEvent, slug: string) {
    if (e.key === 'Escape') {
      e.stopPropagation();
      this.buttonRefs[slug].focus();
    }
  }

  handleTabClick (slug: string) {
    this.setState({
      selectedTabSlug: slug
    },
      () => {
        this.updateLocalStorage();
        this.panelRefs[slug].setAttribute('tabindex', '-1');
        this.panelRefs[slug].focus();
      }
    );
  }

  render() {
    return (
      <nav ref={el => this.mainRef = el} className="persona-bar-neo" aria-label="Resource Links">
        <button
          className="persona-bar-neo__top"
          onClick={() => this.handleExpanderClick()}
          aria-controls="persona-bar-neo__panel"
          aria-expanded={this.state.panelOpen}
          ref={el => this.mainButtonRef = el}
        >
          <div className="persona-bar-neo__panel-header">
            <div className="persona-bar-neo__panel-header-label-container">
              <TransitionGroup className="persona-bar-neo__panel-header-label">
                { this.state.panelOpen &&
                  <CSSTransition
                    classNames="transition-slide-right"
                    timeout={{enter: 300}}
                  >
                    <div className="persona-bar-neo__panel-header-blurb">
                      Resources for
                    </div>
                  </CSSTransition>
                }
              </TransitionGroup>
              <TransitionGroup className="persona-bar-neo__panel-header-label">
                { ! this.state.panelOpen &&
                  <CSSTransition
                    classNames="transition-slide-left"
                    timeout={{enter: 300}}
                  >
                    <div className="d-flex">
                      <div className="persona-bar-neo__panel-header-blurb">Resources for:</div>
                      <div
                        className="ml-2 persona-bar-neo__panel-header-title"
                      >{this.props.nav.find(item => item.slug === this.state.selectedTabSlug).title}</div>
                    </div>
                  </CSSTransition>
                }
              </TransitionGroup>
            </div>
            <span
              className={'fa fa-chevron-down persona-bar-neo__open-indicator' +
                (this.state.panelOpen ? ' persona-bar-neo__open-indicator--open' : '')}
              aria-hidden="true"
            ></span>
          </div>
        </button>
        <div
          className="persona-bar-neo__panel-container"
          onKeyDown={e => this.handleMainKeyDown(e)}
        >
          <TransitionGroup>
            { this.state.panelOpen &&
              <CSSTransition
                classNames="transition-slide-down"
                timeout={{enter: 200, exit: 200}}
              >
                <div className="persona-bar-neo__panel">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-lg-3 order-lg-2">
                        <ul className="persona-bar-neo--options" role="tablist">
                          {this.props.nav.map(item => (
                            <li>
                              <button
                                className={'persona-bar-neo__tab' +
                                  (this.state.selectedTabSlug === item.slug ?
                                    ' persona-bar-neo__tab--active' : '')}
                                id={`persona-bar-tab-${item.slug}`}
                                href={`#${item.slug}`}
                                aria-controls={item.slug}
                                aria-selected={this.state.selectedTabSlug === item.slug}
                                onClick={() => this.handleTabClick(item.slug)}
                                ref={el => this.buttonRefs[item.slug] = el}
                              >
                                {item.title}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-12 col-lg-9 order-lg-1">
                        {this.props.nav.filter(item => item.slug === this.state.selectedTabSlug).map(item => (
                          <div
                            className="d-flex justify-content-start flex-wrap persona-bar-neo__links"
                            role="tabpanel"
                            aria-labelledby={`persona-bar-tab-${item.slug}`}
                            key={item.slug}
                            ref={el => this.panelRefs[item.slug] = el}
                            onKeyDown={e => this.handleLinksKeyDown(e, item.slug)}
                          >
                            {item.children.map(subitem => (
                              <a
                                className="col-6 col-sm-4 col-lg persona-bar-neo__link"
                                href={subitem.url}
                                target={subitem.new_tab ? "_blank" : ''}
                              >
                                <span className={`persona-bar-neo__link-icon ea`} aria-hidden="true">
                                  <SwoopIcon icon={subitem.icon}/>
                                </span>
                                <span className="persona-bar-neo__link-title">{subitem.title}</span>
                              </a>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            }
          </TransitionGroup>
        </div>
      </nav>
    );
  }
}

declare let personaBarNav: NavItem[];

export default class PersonaBarActivator {
  ref?: PersonaBar;
  componentRef?: HTMLElement;

  constructor () {
    this.componentRef = null;
    const el = document.getElementById('persona-bar');
    if (el && personaBarNav) {
      render(<PersonaBar nav={personaBarNav} ref={el => this.componentRef = el}/>, el);
    }
  }

  closePanel() {
    this.ref.closePanel();
  }
}

import {h, render} from 'preact';

interface Gizmo {
    className: string
    datasetKeys?: string[]
    importPromise: () => Promise<any>
}

const gizmos: Gizmo[] = [
    {
        className: 'portfolio-app',
        datasetKeys: ['items'],
        importPromise: () => import(/* webpackChunkName: "portfolio" */ '../portfolio/portfolio'),
    },
];

export default class GizmoActivator {
  constructor() {
    gizmos.map((gizmo) => {
      const elements = [...document.getElementsByClassName(gizmo.className)];
      if (elements.length < 1) {
        return;
      }

      gizmo.importPromise().then((mod: any) => {
        const Gizmo = mod.default;
        elements.forEach((element: HTMLElement) => {
          const props: {[key: string]: string} = {};
          if ('datasetKeys' in gizmo) {
            gizmo.datasetKeys.forEach((key) => {
              if (key in element.dataset) {
                props[key] = element.dataset[key];
              }
            })
          }
          render(
            <Gizmo {...props} />
            , element
          )
        });
      });
    });
  }
}

import { h, render, Component } from 'preact';

import ResultsHeader from './result-header';
import ResultPerson from './result-person';
import NullResults from './null-results';

export default class PeopleResultsSection extends Component {
  render(props) {
    const { results, title, count } = this.props;

    if (!results || !results.length) {
      return <NullResults />
    }

    return (
      <section className="search__result-section">
        <ResultsHeader
          title={title}
          resultsCount={count ? count : results.length}
        />

        {results.map((result) => (
          <ResultPerson result={result} />
        ))}
      </section>
    )
  }
}

import { h, Component } from "preact";

import ResultsSection from "./results-section";
import GoogleResults from "./google-results";

export default class ResultsList extends Component {
  render(props, state) {
    const {
      topResults,
      departmentResults,
      searchResults,
      searchRequest,
      term,
      type
    } = props;

    return (
      <div>
        {term.length >= 3 ? (
          <ResultsSection title="Top Results" results={topResults} />
        ) : null}

        <ResultsSection
          title="Departments / Programs"
          results={departmentResults}
        />

        {type == 'EWU' ? (
          <GoogleResults />
        ) : null}
      </div>
    );
  }
}

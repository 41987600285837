import {Component, h, createRef} from 'preact';

export default class Popup extends Component {

  constructor(){
    super();
    this.state = {
      title: '',
    }
    this.popupRef = createRef();
  }

  componentDidMount() {
    this.props.openPopup();
    this.handleResize();
    window.addEventListener('resize', () => this.handleResize());
  }

  handleResize() {
    this.props.resizePopup();
  }

  handleCloseClick() {
    this.props.closePopupWithFocusReturn();
  }

  handleKeyDown(e) {
    if (e.key==='Escape') {
      this.props.closePopupWithFocusReturn();
    }
  }

  render () {
    let popupClassNameArr = ["ewu-popup"];
    popupClassNameArr.push("ewu-popup--" + this.props.visualState);
    return (
      <div
        ref={(el) => this.popupRef = el}
        className={popupClassNameArr.join(" ")}
        role="dialog"
        aria-label={this.props.dialogLabel}
        tabIndex="0"
        onKeyDown={e => this.handleKeyDown(e)}
      >
        <div class="ewu-popup__header">
          <h4 className="hr-small" style={{textTransform: "none"}}>
            {this.props.title}
          </h4>
          <button
            className="ewu-popup__close-button"
            onClick={e => this.handleCloseClick()}
            aria-label="Close Popup"
          >
            <span className="fa fa-close"></span>
          </button>
        </div>
        {this.props.children}
      </div>
    );
  }
}

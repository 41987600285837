import { h, render } from 'preact';

export default class EmailSigToolActivator {
  constructor () {
    const elements = [...document.querySelectorAll('.email-sig-tool')]
    if (elements.length < 1) {
      return;
    }
    import(/* webpackChunkName: "email-sig-tool" */ './email-sig-tool').then(mod => {
      const EmailSigTool = mod.default;
      elements.forEach(element => {
        render(<EmailSigTool
          content={element.dataset.content}
          logoUrl={element.dataset.logoUrl}
        />, element);
      })
    });
  }
}

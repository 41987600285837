import { h, render, Component } from 'preact';

import ResultsHeader from './result-header';
import Result from './result';

export default class ResultsSection extends Component {
  render(props) {
    const { results, title, count, searchType } = this.props;

    if (!results || !results.length) {
      return null;
    }

    return (
      <section className="search__result-section">
        <ResultsHeader
          title={title}
          resultsCount={count ? count : results.length}
        />

        {results.map((result) => (
          <Result result={result} />
        ))}
      </section>
    )
  }
}

import { h, render } from 'preact';
import EwuPopupManager from '../ewu-popup/ewu-popup-manager';
import * as pe from './program-explorer-types';

declare let programExplorerAppData: pe.ProgramExplorerData;

export default class ProgramExplorerActivator {
  constructor(popupManager: EwuPopupManager) {
    const element = document.querySelector('.program-explorer-app') as HTMLElement;
    if (!element) {
      return;
    }
    import(/* webpackChunkName: "program-explorer" */ './program-explorer').then(mod => {
      const ProgramExplorerApp = mod.default;

      const toReplace = element.querySelector('.program-explorer-loading');

      render(<ProgramExplorerApp
        programs={programExplorerAppData.programs}
        degrees={programExplorerAppData.degrees}
        filters={programExplorerAppData.filters}
        gradStudiesApplyUrl={element.dataset['gradStudiesApplyUrl']}
        gradStudiesApplyText={element.dataset['gradStudiesApplyText']}
        bgImageUrl={element.dataset['bgImageUrl']}
        defaultMetaDescription={element.dataset['defaultMetaDescription']}
        initialQueryString={location.search}
        popupManager={popupManager}
      />, element, toReplace);
    });
  }
}

class ImageLoader {
  constructor() {
    const self = this;
    if('IntersectionObserver' in window && document.cookie.split("skipImageLoader").length != 2) {
      this.observer = new IntersectionObserver(function(items, observer) {
        items.forEach(function(item) {
          if(item.isIntersecting) {
            this.loadImage(item.target);
            observer.unobserve(item.target);
          }
        }, self);
      }, {
        rootMargin: '600px'
      });
    } else {
      console.log("Skipping image loader");
      this.observer = null;
    }
    this.refresh();
  }

  loadImage(el) {
    if (el.getAttribute('data-src')) {
      el.setAttribute('src', el.getAttribute('data-src'));
      el.onload = function() {
        el.removeAttribute('data-src');
      };
    }
    if (el.getAttribute('data-bg-image')) {
      el.style.backgroundImage = 'url(' + el.getAttribute('data-bg-image') + ')';
      el.removeAttribute('data-bg-image');
    }
  };

  observeImage(el) {
    if (this.observer) {
      this.observer.observe(el);
    } else {
      this.loadImage(el);
    }
  }

  refresh() {
    [...document.querySelectorAll('img[data-src],[data-bg-image]')].forEach(
      img => this.observeImage(img)
    );
  }
}

export default ImageLoader;

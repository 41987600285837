import {h, render} from 'preact';

export default class ExpertsExplorerActivator {
  constructor() {
    const elements = [...document.getElementsByClassName('experts-explorer')];
    if (elements.length < 1) {
      return;
    }
    import(/* webpackChunkName: "experts-explorer" */ './experts-explorer').then(mod => {
      const ExpertsExplorer = mod.default;
      elements.forEach((element) =>
        render(
          <ExpertsExplorer />
          , element
        )
      );
    });
  }
}

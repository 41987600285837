class Global {
  constructor() {

    // in-page navigation
    const inPageNav = document.querySelector('.inpagenav');
    if (inPageNav) {

      // create the in-page nav from the components which have it enabled
      const map = new Map();
      const navigableComponents = document.querySelectorAll('.in-page-navigable');

      // add links to desktop in-page nav
      const navList = document.querySelector('.inpagenav nav ul');
      const mobileInPageNavList = document.querySelector('.inpagenav-narrow__nav ul');
      navigableComponents.forEach((component) => {
        const nickname = component.dataset.inPageNavNickname;
        const id = component.dataset.inPageNavId;
        let listItem = document.createElement('li');
        let anchor = document.createElement('a');
        navList.appendChild(listItem);
        listItem.appendChild(anchor);
        anchor.innerText = nickname;
        anchor.setAttribute('href', '#' + id);
        map.set(id, anchor);
        // add link to mobile in-page nav
        if (mobileInPageNavList) {
          listItem = document.createElement('li');
          anchor = document.createElement('a');
          mobileInPageNavList.appendChild(listItem);
          listItem.appendChild(anchor);
          anchor.innerText = nickname;
          anchor.classList.add('inpagenav-narrow__link');
          anchor.setAttribute('href', '#' + id);
        }
      });

      let selectedInPageNavId = "";
      // track the current item and highlight it
      const highlightCurrentInPageNav = () => {
        // find if any navigable element covers the vertical center of the browser
        let currentElement = null;
        let newInPageNavId = "";
        for (let i = 0; i < navigableComponents.length; i++) {
          let el = navigableComponents[i];
          if (
            el.getBoundingClientRect().top < window.innerHeight / 2 &&
            el.getBoundingClientRect().bottom > window.innerHeight / 2
          ) {
            currentElement = el;
            newInPageNavId = currentElement.dataset.inPageNavId;
            break;
          }
        }
        // highlight the current link
        if (newInPageNavId != selectedInPageNavId && currentElement) {
          selectedInPageNavId = newInPageNavId;
          map.forEach((link, key) => {
            link.classList.remove('inpagenav-current');
          });
          const link = map.get(currentElement.dataset.inPageNavId);
          link.classList.add('inpagenav-current');
        }
      };

      // set the initial highlight
      highlightCurrentInPageNav();

      // track highlight upon scrolling
      window.addEventListener('scroll', () => {
        highlightCurrentInPageNav()
      });

      // in-page nav toggler on mobile
      const toggleNav = function (el) {
        const nav = document.querySelector('.inpagenav-narrow');
        nav.classList.toggle('inpagenav-narrow--active');
      }
      const toggler = document.querySelector('.inpagenav-narrow__header');
      toggler.addEventListener('click', toggleNav);
      const navLinks = document.querySelectorAll('.inpagenav-narrow__link');
      for (let i=0; i<navLinks.length; i++) {
        navLinks[i].addEventListener('click', toggleNav);
      }

      // move the nav into the fixed header if it's been scrolled off the page
      // then move it back if it gets scrolled back onto the page
      const inPageNavHolder = document.querySelector('.inpagenav-holder');
      const fixedTop = document.querySelector('.ewu-fixed-top');
      const fixedTopOriginal = document.querySelector('.fixed-top-original');

      function inPageNavScrollWatcher() {
        if (inPageNavHolder.getBoundingClientRect().top < fixedTopOriginal.getBoundingClientRect().bottom) {
          inPageNavHolder.style.height = inPageNav.offsetHeight + 'px';
          fixedTop.appendChild(inPageNav);
        } else {
          inPageNavHolder.appendChild(inPageNav);
        }
      }

      document.addEventListener('scroll', inPageNavScrollWatcher);

      // run when page loads, in case they're already scrolled down
      inPageNavScrollWatcher();
    }

    // fragment links which 
    const linksToAnchors = document.querySelectorAll('a[href*="#"]:not([href="#"]):not([data-toggle]):not(.no-fragment-scroll)');

    linksToAnchors.forEach((el) => (el.addEventListener('click', function (e) {

      const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);

      if (location.pathname.replace(/^\//, '') !== this.pathname.replace(/^\//, '') || location.hostname !== this.hostname) {
        return;
      }

      e.preventDefault();

      const targetID = this.hash;
      let targetAnchor = document.querySelector(targetID);

      if (!targetAnchor) {
        targetAnchor = document.querySelector('[name=' + this.hash.slice(1) + ']');
      }

      if (!targetAnchor) {
        return;
      }

      const originalTop = distanceToTop(targetAnchor);

      targetAnchor.scrollIntoView({behavior: "smooth"});

      // focus the element when done - a11y
      const checkIfDone = setInterval(() => {
        const atBottom = window.innerHeight + window.pageYOffset >= document.body.scrollHeight;
        if (atBottom || Math.abs(distanceToTop(targetAnchor)) < 10) {
          targetAnchor.tabIndex = "-1";
          targetAnchor.focus();
          // window.history.pushState("", "", targetID); // We don't really want to manipulate the browser history for on-page scrolling
          clearInterval(checkIfDone);
        }
      }, 50);

      setTimeout(() => {
        // sometimes the user will click around or manually scroll while the page is autoscrolling and the above thing will never complete
        // in that case we don't want that interval checker running forever and then focusing some element whenever the user happens to finally scroll by it
        clearInterval(checkIfDone);
      }, 1000);
    })));

    // shrink nav bar after scrolling
    if ('IntersectionObserver' in window) {
      const marker = document.querySelector('.main-content-top-marker');
      const nav = document.querySelector('.primary-nav');
      const personaBar = document.getElementById('persona-bar');
      const className = 'primary-nav--scrolled';
      if (
        marker &&
        nav &&
        personaBar
      ) {
        (new IntersectionObserver(
          function(entries) {
              if (entries[0].isIntersecting) {
                  nav.classList.remove(className);
                  if (personaBar) {
                    personaBar.style.display = '';
                  }
              } else {
                  nav.classList.add(className);
                  if (personaBar) {
                    personaBar.style.display = 'none';
                  }
              }
          }
        )).observe(marker);
      }
    }


    // inject icons with inline svg
    [...document.getElementsByClassName('ea')].map((el) => {
      // avoid replacing ones that were already handled in js, for example
      if (el.firstChild?.nodeName == "svg") {
        return;
      }
      // if the actual icon name isn't specified, leave it alone
      if (!el.className.match(/ea-[\w\d-]+/)) {
        return;
      }
      const iconName = el.className.replace(/.*ea-([\w\d-]+).*/, "$1");
      const icon = iconName + ".svg";
      fetch(__webpack_public_path__ + "../assets/icons/" + icon)
        .then((resp) => {
          if (!resp.ok) {
            throw new Error(`Bad request for icon '${icon}': ${resp.url}`);
          }
          return resp.text();
        })
        .then((text) => el.innerHTML = text);
    });

    // get utm params in our own cookie for possible use
    (function() {
      const url = new URL(window.location);
      const params = url.searchParams;
      const fields = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_id', 'utm_term', 'utm_content',];
      for (const field of fields) {
        if (params.has(field)) {
          window.localStorage.setItem('ewu_' + field, params.get(field));
        }
      }
    })();
  }
}

export default Global;

import { Component, render, h } from 'preact';
import * as pe from './program-explorer-types';

declare let programExplorerData: pe.ProgramExplorerData;
declare let dataLayer: any[];

export default class ProgramSearchBoxActivator {
  constructor() {
    [...document.getElementsByClassName('program-search-box')].forEach(function (element: HTMLElement) {
      const props: ProgramSearchBoxProps = {
        degreeFilterFunction: () => true,
        showPrograms: true,
        urlBase: '/degrees/',
        openLinksNewTab: false,
        style: '',
        directDegreeLinks: false,
        placeholderText: 'Find degrees & programs',
      };

      if (element.dataset['style'] === 'white') {
        props.style = element.dataset['style'];
      }

      if (element.dataset['newTab']) {
        props.openLinksNewTab = true;
      }

      if (element.dataset['urlBase']) {
        props.urlBase = element.dataset['urlBase'];
      }

      if (element.dataset['placeholderText']) {
        props.placeholderText = element.dataset['placeholderText'];
      }

      if (element.dataset['searchType'] === 'gradDegrees') {
        props.showPrograms = false;
        props.directDegreeLinks = true;
        props.degreeFilterFunction = (degree: pe.Degree) => {
          if ('degrees' in degree.taxonomies && degree.taxonomies.degrees.includes('grad')) {
            return true;
          } else {
            return false;
          }
        }
      }

      render(
        <ProgramSearchBox {...props}/>
        , element
      );
    });
  }
}

interface SearchResults {
  degrees: pe.Degree[],
  programs: pe.Program[],
  degreesTrimmedQty: number,
  programsTrimmedQty: number,
}

interface ProgramSearchBoxProps {
  style: '' | 'white',
  openLinksNewTab: boolean,
  showPrograms: boolean,
  degreeFilterFunction: (degree: pe.Degree) => boolean;
  urlBase: string,
  directDegreeLinks: boolean,
  placeholderText: string,
}

class ProgramSearchBox extends Component<ProgramSearchBoxProps, {
  search: string,
  degrees: pe.Degree[],
  programs: pe.Program[],
  loadStatus: 'unloaded' | 'loading' | 'loaded',
  closeResults: boolean,
}> {

  containerRef?: HTMLElement;

  static defaultProps = {
    style: '',
    openLinksNewTab: true,
    showPrograms: true,
    degreeFilterFunction: () => true,
    urlBase: '/degrees/',
    directDegreeLinks: false,
    placeholderText: 'Find degrees & programs',
  };

  constructor() {
    super();
    this.state = {
      search: '',
      programs: [],
      degrees: [],
      loadStatus: 'unloaded',
      closeResults: false,
    }

    this.containerRef = null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
  }

  handleClickOutside(e: MouseEvent) {
    if (this.containerRef && !this.containerRef.contains(e.target as HTMLElement)) {
      this.closeResults();
    }
  }

  closeResults() {
    this.setState({
      closeResults: true,
    });
  }

  loadData() {
    if (this.state.loadStatus === 'unloaded') {
      this.setState({
        loadStatus: 'loading',
      }, () => {
        fetch('/wp-json/ewu/api/program-explorer-json')
        .then(response => response.json())
        .then((peData: pe.ProgramExplorerData) => {
          let degrees = peData.degrees;
          if (this.props.degreeFilterFunction) {
            degrees = degrees.filter(this.props.degreeFilterFunction);
          }
          this.setState({
            programs: peData.programs,
            degrees: degrees,
            loadStatus: 'loaded',
          });
        });
      });
    }
  }

  handleInput(event: Event): void {
    const target = event.currentTarget as HTMLInputElement;
    this.setState({
      search: target.value,
      closeResults: false,
    });

    this.loadData();
  }

  getResultsForSearch(): SearchResults {
    const resultsLimit = 5;
    const search = this.state.search;
    let programs = this.state.programs.filter(p => this.programMatchesSearch(p, search));
    let degrees = this.state.degrees.filter(d => this.degreeMatchesSearch(d, search));
    let programsTrimmedQty = 0;
    let degreesTrimmedQty = 0;

    if (programs.length > resultsLimit) {
      programsTrimmedQty = programs.length - resultsLimit;
      programs = programs.slice(0, resultsLimit);
    }

    if (degrees.length > resultsLimit) {
      degreesTrimmedQty = degrees.length - resultsLimit;
      degrees = degrees.slice(0, resultsLimit);
    }

    return {
      degrees: degrees,
      programs: programs,
      degreesTrimmedQty: degreesTrimmedQty,
      programsTrimmedQty: programsTrimmedQty,
    };
  }

  degreeMatchesSearch(degree: pe.Degree, search: string): boolean {
    if ((degree.title + ' ' + degree.subtitle + ' ' + degree.suffix).toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    return false;
  }

  programMatchesSearch(program: pe.Program, search: string): boolean {
    let match = false;
    if (program.title.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }

    const searchTerms = search.split(/[^a-z]/i).map(item => item.toLowerCase());

    searchTerms.forEach((searchTerm) => {
      program.tags.forEach((tag) => {
        if (tag.includes(searchTerm)) {
          match = true;
        }
      });
    });

    return match;
  }

  getURLForSearch() {
    return this.props.urlBase + '?query=' + this.state.search;
  }

  getURLForProgram(program: pe.Program): string {
    return this.props.urlBase + '?program=' + program.slug;
  }

  getUrlForDegree(degree: pe.Degree): string {
    if (this.props.directDegreeLinks) {
      return this.props.urlBase + '?degree=' + degree.slug;
    }
    const program = this.getProgramForDegree(degree);
    if (!program) {
      // this degree doesn't have any associated program so it's invalid
      return;
    }

    return this.props.urlBase + '?program=' + program.slug;
  }

  getProgramForDegree(degree: pe.Degree): pe.Program {
    return this.state.programs.find(program => program.degrees.includes(degree.id));
  }

  highlightText(text: string, search: string): string {
    return text.replace(
      new RegExp(`(${search})`, 'gi'),
      '<strong>$1</strong>'
    );
  }

  getDegreeTitle(degree: pe.Degree): string {
    const subtitle = degree.subtitle ? `: ${degree.subtitle}` : '';
    const suffix = degree.suffix ? ` (${degree.suffix})` : '';
    return `${degree.plain_title}${subtitle}${suffix}`;
  }

  handleItemClick(eventLabel: string) {

    dataLayer.push({
      event: 'site_event',
      event_category: 'Program Explorer Intro',
      event_action: 'Item Click',
      event_label: eventLabel,
    });
  }

  handleSearchSubmit() {
    dataLayer.push({
      event: 'site_event',
      event_category: 'Program Explorer Intro',
      event_action: 'Search Submit',
      event_label: this.state.search,
    });
  }

  handleSearchInputFocus() {

    this.loadData();

    // reopen the results if they were closed by clicking outside
    this.setState({
      closeResults: false,
    });

    dataLayer.push({
      event: 'site_event',
      event_category: 'Program Explorer Intro',
      event_action: 'Search Box Focus',
    });
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.setState({
        closeResults: true,
      });
    }
  }

  render() {
    const results = this.getResultsForSearch();
    const classNames = ['stuff-search'];
    if (this.props.style === 'white') {
      classNames.push('stuff-search--white');
    }
    return (
      <div
        ref={(el) => this.containerRef = el}
        class="program-search-box"
      >
        <form
          className={classNames.join(' ')}
          action={this.props.urlBase}
          onSubmit={() => this.handleSearchSubmit()}
        >
          <input
            placeholder={this.props.placeholderText}
            aria-label={this.props.placeholderText}
            type="text"
            className="stuff-search__input"
            onInput={e => this.handleInput(e)}
            onKeyDown={e => this.handleKeyDown(e)}
            onFocus={() => this.handleSearchInputFocus()}
            name="query"
            autocomplete="off"
          />
          <button
            class="stuff-search__button"
            aria-label="Program Search"
          >
            <span class="fa fa-fw fa-search"></span>
          </button>
        </form>
        {this.state.search.length > 0 && !this.state.closeResults && (
          this.state.loadStatus === 'loaded'
          ? (
            <div class="program-search-box__results">
              {this.props.showPrograms && results.programs.length > 0 && (
                <div>
                  <div class="program-search-box__results-label">Programs</div>
                  <ul class="program-search-box__results-list">
                    {results.programs.map(p => {
                      const link = this.getURLForProgram(p);
                      return (
                        <li key={p.title}>
                          <a
                            class="program-search-box__result"
                            href={link}
                            onClick={() => this.handleItemClick(`Program: ${p.title}`)}
                            target={this.props.openLinksNewTab ? '_blank' : ''}
                          >
                            <span dangerouslySetInnerHTML={{
                              __html: this.highlightText(p.title, this.state.search)
                            }}></span>
                          </a>
                        </li>
                      );
                    })}
                    {results.programs.length > 1 && (
                      <li>
                        <a
                          class="program-search-box__result"
                          href={this.getURLForSearch()}
                          onClick={() => this.handleItemClick(`More Results`)}
                          target={this.props.openLinksNewTab ? '_blank' : ''}
                        >
                          {results.programsTrimmedQty > 0
                          ? (
                            <em>{results.programsTrimmedQty} More Results &gt;</em>
                          )
                          : (
                            <em>All Results &gt;</em>
                          )}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
              {results.degrees.length > 0 && (
                <div>
                  {this.props.showPrograms && (
                    <div class="program-search-box__results-label">Degrees</div>
                  )}
                  <ul class="program-search-box__results-list">
                    {results.degrees.map(d => {
                      const link = this.getUrlForDegree(d);
                      return (
                        <li key={d.id}>
                          <a
                            class="program-search-box__result"
                            href={link}
                            onClick={() => this.handleItemClick(`Degree: ${d.title}`)}
                            target={this.props.openLinksNewTab ? '_blank' : ''}
                          >
                            <span dangerouslySetInnerHTML={{
                              __html: this.highlightText(this.getDegreeTitle(d), this.state.search)
                            }}></span>
                          </a>
                        </li>
                      );
                    })}
                    {results.degrees.length > 1 && (
                      <li>
                        <a
                          class="program-search-box__result"
                          href={this.getURLForSearch()}
                          onClick={() => this.handleItemClick(`More Results`)}
                          target={this.props.openLinksNewTab ? '_blank' : ''}
                        >
                          {results.degreesTrimmedQty > 0
                          ? (
                            <em>{results.degreesTrimmedQty} More Results &gt;</em>
                          )
                          : (
                            <em>All Results &gt;</em>
                          )}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div class="program-search-box__results">
              <ul class="program-search-box__results-list">
                <li>
                  <span class="program-search-box__result">
                    <span style={{
                      color: '#666',
                    }}>
                      <span class="fa fa-fw fa-gear fa-spin"></span>
                      {' '}
                      <span><em>Searching...</em></span>
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          )
        )}
      </div>
    );
  }
}

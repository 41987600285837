import ImageLoader from '../image-loader/image-loader';

export default class EasternStoriesActivator {
  constructor(config: {imageLoader: ImageLoader}) {
    const element = document.querySelector('.eastern-stories');
    if (!element) {
      return;
    }
    import(/* webpackChunkName: "eastern-stories" */ './eastern-stories').then(mod => {
      const EasternStories = mod.default;
      new EasternStories({imageLoader: config.imageLoader});
    });
  }
}

export default class MapActivator {
  constructor() {
    const element = document.getElementById('jsCampusMap');
    if (!element) {
      return;
    }
    import(/* webpackChunkName: "map" */ './map').then(mod => {
      const Map = mod.default;
      new Map();
    });
  }
}

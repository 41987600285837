import { h, render, Component } from 'preact';

class GridImage extends Component {
  render(props) {
    const imageUrl = props.thumbnail_url ? props.thumbnail_url : props.media_url;

    let formattedCaption = '';
    let maxCaptionLength = 280;
    if (!props.caption) {
      formattedCaption = '';
    } else if (props.caption.length > maxCaptionLength) {
      formattedCaption = props.caption.substring(0, maxCaptionLength) + '...';
    } else {
      formattedCaption = props.caption;
    }
    return (
      <a
        class={props.feature ? 'instagram-subgrid__image instagram-subgrid__image--feature' : 'instagram-subgrid__image'}
        href={props.permalink}
        target='_blank'
        style={imageUrl ? {backgroundImage: `url(${imageUrl})`} : {}}
      >
        { formattedCaption ? (
          <div class='instagram-hover'>
            <div>
              <div class='instagram-hover__caption'>
                <p style={{margin: 0}}>{formattedCaption}</p>
              </div>
            </div>
          </div>
        ) : (
          <span>{props.feature ? 'Checking Instagram...' : ''}</span>
        )}
      </a>
    );
  }
}

class GridImages extends Component {

  constructor() {
    super();
    this.containerRef = null;
    this.state = {
      images: [],
      loaded: false,
    };
  }

  componentDidMount() {
    const _this = this;
    const observer = new IntersectionObserver(function(items, observer) {
      items.forEach(function(item) {
        if(item.isIntersecting && !_this.state.loaded) {
          observer.unobserve(item.target);
          fetch(wp.ajaxUrl + '/wp-json/ewu/api/instagram-json?identifier=' + _this.props.identifier)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not OK - check response for error message.');
            }
            return response.json();
          })
          .then(json => {
            let images = json.data;
            let ignoreUrls = JSON.parse(_this.props.ignoreUrls);
            if (ignoreUrls && Array.isArray(ignoreUrls)) {
              images = images.filter(item => !ignoreUrls.includes(item.permalink));
            }
            _this.setState({
              images: images,
              loaded : true,
            });
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }
      });
    }, {
      rootMargin: '600px'
    });
    observer.observe(this.containerRef);
  }

  render() {
    let images = null;
    if (this.state.loaded) {
      images = this.state.images.slice(0, 7);
    } else {
      images = [1, 2, 3, 4, 5, 6, 7]; // these will be blank until it loads
    }

    return (
      <div className="instagram-subgrid" ref={(el) => this.containerRef = el}>
        { images.map(function(item, index) {
            return (
              <GridImage feature={index == 0} {...item} />
            );
        })}
      </div>
    )
  }
}

class Instagram {
  constructor() {
    const element = document.getElementById('jsInstagramSubgrid')
    if (!element || !('IntersectionObserver' in window)) {
      return;
    }
    render(
      <GridImages
        identifier={element.dataset['identifier']}
        ignoreUrls={element.dataset['ignoreUrls']}
      />,
      element
    );
  }
}

export default Instagram;

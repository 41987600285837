import {render, h} from 'preact';
import EwuPopupManager from './ewu-popup-manager';

export default class PopupManagerActivator {
  constructor() {
    const container = document.createElement('span');
    document.body.appendChild(container);
    render(
      <EwuPopupManager
        ref={el => this.popupManager = el}
      />, container
    );
  }
}

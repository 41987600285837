import WebFont from 'webfontloader';

export default class FontLoader {
  constructor() {
    WebFont.load({
      google: {
        families: ['Open Sans:300,400,400italic,500,600,700&display=swap']
      }
    });
  }
}

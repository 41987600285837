import {h, render} from 'preact';

export default class AdmissionsExplorerActivator {
  constructor() {
    const elements = [...document.querySelectorAll('.admissions-explorer')];
    if (elements.length < 1) {
      return;
    }
    import(/* webpackChunkName: "admissions-explorer" */ './admissions-explorer').then(mod => {
      const AdmissionsExplorer = mod.default;
      elements.forEach((element) => {
        render(
          <AdmissionsExplorer />
          , element
        );
      });
    });
  }
}

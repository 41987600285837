import { h, render, Component } from 'preact';

export default class NullResults extends Component {
  render(props) {
    return (
      <div>
        <h2 class="text-center hr-none">No Results Found</h2>
      </div>
    );
  }
}
